@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    background-color: #000000;
}

.MuiTypography-p {
    font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

/* Styles for track player */
.rhap_container {
    background-color: #000000;
    border-top: 2px solid #C6B79B;
}

.rhap_play-pause-button {
    color: #BA0F30;
}

.rhap_time {
    color: #fff;
}